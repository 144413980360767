import styled, { css } from "styled-components";
import { CardWrapper, ImgCard, CardContainer } from "../ArtCards/art-submissions-card.styles.jsx";

import { device } from '../../utils/device';

export const HomeCardContainer = styled(CardContainer)`
    flex-basis: 33%;
`;
export const HomeCardWrapper = styled(CardWrapper)`
    
    min-height: 110px;
    width: 110px;
    border: 1px solid #CCC;
    align-items: center;
    margin: 10px auto;

    @media ${device.desktopM} {
        min-height: 80px;
        width: 80px;
    }
`;

export const HomeImgCard = styled(ImgCard)`

    max-width: 110px;
  
    @media ${device.desktopM} {
        max-width: 80px;
    }
`;