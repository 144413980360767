const UserActionTypes = {
  // Set User Session
  SET_CURRENT_USER: "SET_CURRENT_USER",
  SET_USER_JWT_TOKEN: "SET_USER_JWT_TOKEN",
  // SignIn & SignUp
  SIGN_IN_START: "SIGN_IN_START",
  SIGN_UP_START: "SIGN_UP_START",
  AUTHORIZED_SUCCESS: "AUTHORIZED_SUCCESS",
  AUTHORIZED_FAILURE: "AUTHORIZED_FAILURE",
  // Update User Account
  UPDATE_USER_ACC_START: "UPDATE_USER_ACC_START",
  UPDATE_USER_ACC_SUCCESS: "UPDATE_USER_ACC_SUCCESS",
  UPDATE_USER_ACC_FAILURE: "UPDATE_USER_ACC_FAILURE",
  // Delete User
  DELETE_USER_START: "DELETE_USER_START",
  DELETE_USER_SUCCESS: "DELETE_USER_SUCCESS",
  // Clear Error Messages
  ClEAR_USER_ERROR: "ClEAR_USER_ERROR",
  // Log User Out and Clear everything.
  LOGOUT_START: "LOGOUT_START",
  CLEAR_ALL_USER_DETAILS: "CLEAR_ALL_USER_DETAILS",
};

export default UserActionTypes;
