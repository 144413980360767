
// ArtworkType based on submissions table, artwork_type enum
export const ArtworkType = {
    classic : 'CLASSIC',
    allOver : 'ALL_OVER_PATTERNED'
}

// ScheduleType based on submissions table, schedule_type enum
export const ScheduleType = {
    ODAD : 'ODAD',
    WEEKLY : 'WEEKLY'
}
