import React, { Component } from "react";
import axios from "axios";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectUserJWTToken } from "../../redux/user/user.selector";

import { SITE_BACKEND_API_URL } from "../../server";

import { ArtistTable } from "../Table";
import TableQueries from "../Table/table-queries.component";
//import { artistFetchCommForTable } from "../../utils/fetchCommForTable";
import { SelectColumnFilter } from "../../libs/table";

import { HomeSubmissionContainer, HomeTabArea } from "../SharedStyle/styled";
import { Loader } from "../Common";

const TABLE_COLUMNS = [
  {
    Header: "",
    accessor: "serial",
    disableFilters: true,
    className: 'cell-s-no cell-center',
    Cell: (row) => <div className="cell-s-no-value">{row.value}</div>
  },
  {
    Header: "DESIGN",
    accessor: "product_title",
    disableFilters: true,
  },
  {
    Header: "PAID COMMISSIONS",
    accessor: "total_paid_commissions_amount",
    disableFilters: true,
    className: 'cell-center',
  },
  {
    Header: "UNPAID COMMISSIONS",
    accessor: "total_unpaid_commissions_amount",
    disableFilters: true,
    className: 'cell-center',
  }
];

class ArtistCommissions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      errorMsg: "",
      loading: true,
    };
  }

  componentDidMount() {
    this.getTop5Commissions();
  }

  setTableData = (data) => {
    this.setState({ tableData: data });
  };

  getTop5Commissions = async () => {
    try {
        const { token } = this.props;
        
        const {
            data: { commissionsDetailsArr },
        } = await axios.get(SITE_BACKEND_API_URL+"/artist/commissions/gettop5", {
            headers: { Authorization: `JWT ${token}` },
        });

        // Add serial number
        commissionsDetailsArr.forEach((commission, index) => { commission.serial = index + 1; });

        this.setState({ tableData: commissionsDetailsArr, loading: false });
    } catch (error) {
        this.setState({ errorMsg: "We could not find any records. Let us know if its a mistake."});
    }
  };

  render() {
    const { tableData, errorMsg, loading } = this.state;
    return (
      <HomeSubmissionContainer>
        <HomeTabArea>
          {tableData.length > 1 ? (
            <ArtistTable className="home-table" columns={TABLE_COLUMNS} data={tableData} showPagination={false}/>
          ) : errorMsg ? (
            <h2>{errorMsg}</h2>
          ) : (
            <>
              {loading ? (
                <Loader />
              ) : (
                <h2 style={{ textAlign: "center" }}>
                  Sorry you don't have any commissions yet.
                </h2>
              )}
            </>
          )}
        </HomeTabArea>
      </HomeSubmissionContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  token: selectUserJWTToken,
});

export default connect(mapStateToProps)(ArtistCommissions);
