import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { createStructuredSelector } from "reselect";
import { artistFetchCommForTable } from "../../utils/fetchCommForTable";

import { selectUserAccount } from "../../redux/user/user.selector";
import {
  updateUserStart,
  clearUserError,
  updateUserFailure,
  logoutStart,
} from "../../redux/user/user.action";
import { selectArtistProfile } from "../../redux/artist/artist.selector";
import {
  getArtistProfileStart,
  updateArtistProfileStart,
  clearArtistErrors,
  artistProfileFailure,
} from "../../redux/artist/artist.action";


import ArtistHomeTopCommissions from "./artist-home-top-commissions-table.component";
import ArtistHomeLifetimeCommissions from "./artist-home-lifetime-commissions-table.component";
import ArtistHomeSubmissions from "./artist-home-submissions-table";


class ArtistHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tableData: [],
      errorMsg: "",
      loading: true,
      startDate: new Date(),
      endDate: new Date(),
    };
  }
  static getDerivedStateFromProps(props) {
    const { userAccount } = props;
    return {
      contactEmail:
        userAccount && userAccount.contactEmail ? userAccount.contactEmail : "",
    };
  }

  componentDidMount() {
    const { userAccount, artistProfile } = this.props;
    if (this._redirectUser(userAccount, artistProfile)) {
      this.setState({ ...artistProfile });
    }
  }


  shouldComponentUpdate(nextProps) {
    const { userAccount, artistProfile } = nextProps;
    return this._redirectUser(userAccount, artistProfile);
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
      hasArtistFromSaved: false,
      isDisableArtistSubmit: false,
      hasUserFormSaved: false,
      isDisableUserSubmit: false,
    });
  };

  _redirectUser = (userAccount, artistProfile) => {
    const { history, logOut } = this.props;
    const hasCreatedUserAccount =
      userAccount && userAccount.contactEmail ? true : false;
    const hasCreatedArtistProfile =
      artistProfile && artistProfile.artistName ? true : false;

    if (hasCreatedUserAccount) {
      if (hasCreatedArtistProfile) {
        return true;
      } else {
        history.push("/artist/create");
        return false;
      }
    } else {
      logOut();
      history.push("/");
      return false;
    }
  };

  render() {
    const {
      // Artist Name
      artistName,
    } = this.state;
    return (
      <section>
        <h1 style={{ color: "#6CB6BB", padding: "0 20px" }}>Welcome {artistName}</h1>

        <div style={{ maxWidth: "1600px", padding: "0 20px" }}>
        
            <div style={{display: "flex", justifyContent: "space-between"}}>
                <div style={{width: "49%"}}>
                    <h4>TOP PERFORMING PRODUCTS</h4>
                    <ArtistHomeTopCommissions />
                </div>

                <div style={{width: "49%"}}>
                    <h4>RECENT SUBMISSIONS</h4>
                    <ArtistHomeSubmissions />
                </div>
            </div>
        
            <div>
                <h4 style={{margin: "auto"}}>LIFETIME COMMISSIONS</h4>
                <span>(AS OF JANUARY 1, 2020)</span>
                <ArtistHomeLifetimeCommissions />
            </div>
            
        </div>

      </section>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  userAccount: selectUserAccount,
  artistProfile: selectArtistProfile,
});

const mapDispatchToProps = (dispatch) => ({
  getArtistProfileStart: () => dispatch(getArtistProfileStart()),
  updateArtistProfileStart: (reqBody) =>
    dispatch(updateArtistProfileStart({ reqBody })),
  clearReduxArtistErrors: () => dispatch(clearArtistErrors()),
  updateUserStart: (reqBody) => dispatch(updateUserStart({ reqBody })),
  clearReduxUserErrors: () => dispatch(clearUserError()),
  updateUserFailure: ({ ...errObj }) =>
    dispatch(updateUserFailure({ ...errObj })),
  artistErrorMsg: ({ ...errObj }) =>
    dispatch(artistProfileFailure({ ...errObj })),
  logOut: () => dispatch(logoutStart()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ArtistHome)
);