import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Swal from "sweetalert2";

import { ArtworkType } from "../../constants";
import { SITE_BACKEND_API_URL } from "../../server";

import { cleanFileName } from "../../utils";
import { selectArtistProfile } from "../../redux/artist/artist.selector";
import {
  selectSubmissionsErrorAlert,
  selectSubmissionsSuccessAlert,
} from "../../redux/submissions/submissions.selector";
import {
  submissionCreateStart,
  submissionErrorAlertClear,
  submissionSuccessAlertClear,
} from "../../redux/submissions/submissions.action";

import { ReactComponent as Upload } from "../../assets/upload.svg";
import { ReactComponent as Loading } from "../../assets/loading.svg";
import { InputArtFile, BtnArtSubmit, InputArtPreview } from "../Button";
import { SectionHeading, StyledSelect, StyledMultiSelectCheckbox } from "../FormInput";
import { ArtworkTypeInfoDialog } from "../Dialog";

import {
  SubmissionContainer,
  TabHeader,
  TabTitle,
  TabSubTitle,
  TabSubLink,
  TabArea,
  SubTitle,
  FormArtistSubmit,
  SubmitCard,
  FieldSection,
  ArtPreview,
  IconContainer,
  IconTopSubtitle,
  IconBottomSubtitle,
  FileTypeInfoText,
  PreviewImageWrapper,
  PreviewImage,
  FormInputArtistStyled,
  FormInputTitleStyled,
  TextAreaStyled,
  ArtworkTypeWrapper,
  ArtworkTypeOption,
} from "./artist-submit-art.styles";

import { Select, MenuItem, Checkbox, Dialog } from "@material-ui/core";
//import { CircleChecked, CircleCheckedFilled, CircleUnchecked } from '@material-ui/icons';

import CircleCheckedFilled from '@material-ui/icons/CheckCircle';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close';

import axios from "axios";

const COLORS = axios.get(SITE_BACKEND_API_URL+ '/common/api/getcolors');

class ArtistSubmitArt extends Component {
  constructor(props) {
    super(props);
    
    this.artworkSubmissionForm = React.createRef();

    this.state = {
      artistName: "",
      title: "",
      description: "",
      suggestedTags: "",
      artFileName: "",
      artPreviewImg: "",
      artworkType: ArtworkType.classic,
      mainColor: "",
      additionalColors: [],
      artHasSubmitted: false,
      isDisableSubmit: false,
      colorsList: [],
      additionalColorsList: [],
      isDialogOpen: false,
      openedDialogArtworkType: ArtworkType.classic,
    };
  }

  static getDerivedStateFromProps(props) {
    const {
      artistProfile: { artistName },
    } = props;

    return {
      artistName,
    };
  }

  componentDidMount() {
      COLORS.then((res) => {
        this.setState({colorsList : res.data, additionalColorsList: res.data });
      });
  }

  componentDidUpdate() {
    this._submissionMessages();
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({ [name]: value, isDisableSubmit: false });
  };
  
  handleMainColorChange = (event) => {
    console.log("handleMainColorChange:");
    console.log(event.target.value);
      
    const { colorsList, additionalColors } = this.state;
    
    // Remove main color from additionalColors list
    const updatedAdditionalColorsList = colorsList.filter(color => color['id'] !== event.target.value);
    const updatedAdditionalColors = additionalColors.filter(color => color !== event.target.value);
    
    this.setState({ 
        mainColor : event.target.value, 
        additionalColorsList: updatedAdditionalColorsList,
        additionalColors: updatedAdditionalColors
    });
  };
  
    openDiaog = (e, artworkType) => {
        this.setState({isDialogOpen: true, openedDialogArtworkType: artworkType});
        e.stopPropagation();
    }
    
    closeDiaog = () => {
        this.setState({isDialogOpen: false});
    }
  
  handleAdditionalColorChange = (event) => {
    
    const { additionalColors } = this.state;
    
    // Allowed to select only 3 colors
//    if(additionalColors.length < 4 && event.target.value.length < 4) {
        this.setState({ additionalColors : event.target.value });
//    } else {
//        
//        Swal.fire({
//            icon: "error",
//            text:
//              "Sorry you can select maximum 3 colours only.",
//            showConfirmButton: true,
//          });
//    }
  };
  
  handleArtTypeChange = async (type) => {
      this.setState({ artworkType: type });
  }
  
  handleSubmit = (event) => {
    event.preventDefault();
    this._submitArtwork();
    this.setState({
      isDisableSubmit: true,
    });
  };

  onChangeArtPreview = async (event) => {
    const [file] = event.target.files;

    // Make sure `file.name` matches our extensions criteria
    if (!file || !/\.(jpe?g|png|tif)$/i.test(file.name)) {
        Swal.fire({
            icon: "error",
            text: "Does not meet requirements. See below for file types accepted.",
            showConfirmButton: true,
        });
        return;
    }

    // Make sure `file.size` does not exceed 10MB
    if (file.size > 10000000) {
      Swal.fire({
        icon: "error",
        text: "Sorry your file is too large! Please limit your image size to less than 10MB.",
        showConfirmButton: true,
      });

      return;
    }

    const artPreviewImg = await this._generatePreviewImg(file);
    this.setState({ artPreviewImg, isDisableSubmit: false });
  };

  onChangeArtFile = (event) => {
    let [file] = event.target.files;
    if (!file) return;
    
    console.log(file.name);
    
    const { artworkType } = this.state;
    
    const fileExtention = file.name.split('.').pop();
    const expectedFileExtentions = (artworkType === ArtworkType.classic) ? ['psd', 'ai', 'png'] : ['ai', 'jpg','jpeg', 'png', 'psd'];
//    const expectedFileExtentionNames = (artworkType === ArtworkType.classic) ? ['psd, ai OR png'] : ['ai, jpeg, png OR psd'];
    
    if(!expectedFileExtentions.includes(fileExtention)) {
        Swal.fire({
            icon: "error",
            text:
    //          "Art file type must be "+expectedFileExtentionNames+".",
                "Does not meet requirements. See below for file types accepted.",
            showConfirmButton: true,
        });

      return;
    }
    
    // Make sure `file.size` does not exceed 100 MB
    if (file.size > 100000000) {
      Swal.fire({
        icon: "error",
        text:
          "Sorry your file is too large! Please limit your image size to less than 100MB.",
        showConfirmButton: true,
      });

      return;
    }

    const { name } = file;
    this.setState({ artFileName: cleanFileName(name), isDisableSubmit: false });
  };

  _submitArtwork = () => {
    const { submissionCreateStart } = this.props;
    const { elements } = this.artworkSubmissionForm.current;
    const inputsDOM = Array.from(elements);

    const formData = new FormData();

    inputsDOM.forEach((el) => {
      const { files, name, value } = el;
      if (files) {
        formData.append(name, files[0]);
      } else if (value) {
        formData.append(name, value);
      }
    });

    submissionCreateStart(formData);
    Swal.fire({
      icon: "warning",
      text:
        "Please be patient!!! Allow 1 - 3 minutes for upload. Don't close this window these are large files.",
      showConfirmButton: false,
    });

    this.setState({ artHasSubmitted: true });
  };

  _generatePreviewImg = (file) => {
    return new Promise((resolve, reject) => {
      const loadImg = () => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        resolve(reader.result);
      };

      const loadError = (event) => {
        reader.removeEventListener("load", loadImg);
        reader.removeEventListener("error", loadError);
        reject(event);
      };

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.addEventListener("load", loadImg);
      reader.addEventListener("error", loadError);
    });
  };

  _resetForm = () => {
    const {
      artistProfile: { artistName },
    } = this.props;

    this.artworkSubmissionForm.current.reset();

    this.setState({
      artistName,
      title: "",
      description: "",
      suggestedTags: "",
      artFileName: "",
      artPreviewImg: "",
      artworkType: ArtworkType.classic,
      mainColor: "",
      additionalColors: [],
      artHasSubmitted: false,
      isDisableSubmit: false,
    });
  };

  _submissionMessages() {
    // Check Redux for messages

    const {
      submissionSuccessMsg,
      submissionSuccessAlertClear,
      submissionErrorMsg,
      submissionErrorAlertClear,
    } = this.props;

    if (!!submissionSuccessMsg) {
      Swal.fire({
        icon: "success",
        text: submissionSuccessMsg,
        showConfirmButton: false,
      });

      setTimeout(() => {
        submissionSuccessAlertClear();
        this._resetForm();
      }, 2000);
    } else if (!!submissionErrorMsg) {
      Swal.fire({
        icon: "error",
        text: submissionErrorMsg,
        showConfirmButton: false,
      });

      setTimeout(() => {
        submissionErrorAlertClear();
      }, 2000);
    }
  }

  render() {
    const {
      artistName,
      title,
      description,
      suggestedTags,
      isDisableSubmit,
      artPreviewImg,
      artHasSubmitted,
      artFileName,
      artworkType,
      mainColor,
      additionalColors,
      colorsList,
      additionalColorsList,
      isDialogOpen,
      openedDialogArtworkType,
    } = this.state;

    return (
      <SubmissionContainer>
        <TabHeader>
          <TabTitle>Submit Artwork</TabTitle>
          <TabSubLink to={`/artist/submissions/new`}>
            <TabSubTitle>Submissions</TabSubTitle>
          </TabSubLink>
        </TabHeader>
        <TabArea>
          <SubTitle>Create New Submission</SubTitle>
          <FormArtistSubmit
            onSubmit={this.handleSubmit}
            ref={this.artworkSubmissionForm}
          >
            <input type="hidden" name="artworkType" value={artworkType}/>
            
            <ArtworkTypeWrapper>
                <ArtworkTypeOption onClick={() => this.handleArtTypeChange(ArtworkType.classic)} className={['left', ( artworkType == ArtworkType.classic ? 'active' : '' )]}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{ paddingTop: "2px" }}>Classic Artwork</div>
                        <div><HelpOutlineIcon onClick={(e) => this.openDiaog(e, ArtworkType.classic)} style={{marginLeft: "10px"}} /></div>
                    </div>
                </ArtworkTypeOption>
                <ArtworkTypeOption onClick={() => this.handleArtTypeChange(ArtworkType.allOver)} className={['right', ( artworkType == ArtworkType.allOver ? 'active' : '' )]}>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <div style={{ paddingTop: "2px" }}>All Over/Patterned Artwork</div>
                        <div><HelpOutlineIcon onClick={(e) => this.openDiaog(e, ArtworkType.allOver)} style={{marginLeft: "10px"}} /></div>
                    </div>
                </ArtworkTypeOption>
            </ArtworkTypeWrapper>
            
            <ArtworkTypeInfoDialog artworkType={openedDialogArtworkType} isOpen={isDialogOpen}  handleClose={this.closeDiaog} />
          
            <SubmitCard>
            <FieldSection>
               <SectionHeading serialNumber = "1" label="Preview Image" />
              <label htmlFor="preview-art">
                <InputArtPreview
                  id="preview-art"
                  type="file"
                  name="previewArt"
                  onChange={this.onChangeArtPreview}
                  textAlign="center"
                  required
                  accept="image/png,image/jpeg,image/tiff"
                >
                  {artPreviewImg ? (
                    <PreviewImageWrapper>
                        <PreviewImage
                          src={this.state.artPreviewImg}
                          alt="Art Preview"
                        />
                    </PreviewImageWrapper>
                  ) : (
                    <ArtPreview>
                      <IconContainer>
                        <Upload />
                      </IconContainer>
                      <IconTopSubtitle>Click to upload images</IconTopSubtitle>
                    </ArtPreview>
                  )}
                </InputArtPreview>
                
                <IconBottomSubtitle>
                    jpeg, png, or tif (Max size 10MB)
                </IconBottomSubtitle>
              </label>
              </FieldSection>
              <FieldSection>
                <SectionHeading serialNumber = "2" label="Art File" />
                <label htmlFor="art-file">
                  <InputArtPreview
                    id="art-file"
                    type="file"
                    name="artFile"
                    onChange={this.onChangeArtFile}
                    textAlign="center"
                    required
                  >
                    { /* artFileName */ }
                    
                    <ArtPreview>
                      <IconContainer>
                        <Upload />
                      </IconContainer>
                      <IconTopSubtitle>Click to upload file</IconTopSubtitle>
                      {artFileName ? ( <span style={{ wordBreak: "break-word", position: "absolute", padding: "20px", bottom: "10px" }}>{artFileName}</span> ) : ( "" )}
                    </ArtPreview>
                  </InputArtPreview>
                  
                  <IconBottomSubtitle>
                    { artworkType === ArtworkType.classic ? ( 
                    "psd, png OR ai (At 300 dpi or higher)" 
                    ) : ( 
                        "ai, jpeg, png or psd" 
                    )}
                  </IconBottomSubtitle>
                      
                </label>
                
                { /*
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <FileTypeInfoText>
                    { /* Can be a large file <br /> ai, psd, or eps at 300 dpi  }
                    { artworkType === ArtworkType.classic ? ( 
                        "psd, png OR ai (At 300 dpi or higher)" 
                    ) : ( 
                        "ai, jpeg, png or psd" 
                    )}
                  </FileTypeInfoText>
                </div>
                */ }
              </FieldSection>
            </SubmitCard>
            <SubmitCard>
              <FieldSection>
                <SectionHeading serialNumber = "3" label="Artwork Info" />
                <div>
                  <FormInputTitleStyled
                    type="text"
                    name="artistName"
                    label="artist_name"
                    data-lpignore="true"
                    value={artistName}
                    readOnly
                  />
                  <FormInputTitleStyled
                    type="text"
                    name="title"
                    label="title"
                    placeholder="Title"
                    data-lpignore="true"
                    autoComplete="off"
                    handleChange={this.handleChange}
                    value={title}
                    maxlength="180"
                    required
                  />
                  <TextAreaStyled
                    type="text"
                    name="description"
                    label="Description"
                    placeholder="Tell us more about what inspired this design"
                    data-lpignore="true"
                    autoComplete="off"
                    handleChange={this.handleChange}
                    value={description}
                    maxlength="255"
                    required
                  />
                  
                  <TextAreaStyled
                    type="text"
                    name="suggestedTags"
                    label="Suggested Tags"
                    placeholder="Suggested Tags"
                    data-lpignore="true"
                    autoComplete="off"
                    handleChange={this.handleChange}
                    value={suggestedTags}
                    maxlength="255"
                    required
                  />
                  
                  {artHasSubmitted ? (
                    <BtnArtSubmit
                      type="submit"
                      disabled={true}
                      textAlign="right"
                      style={{
                        backgroundColor: "#0B7C80",
                        cursor: "pointer",
                        width: "95px",
                        height: "45px",
                        padding: "0",
                      }}
                    >
                      <Loading />
                    </BtnArtSubmit>
                  ) : (
                    <BtnArtSubmit
                      type="submit"
                      disabled={isDisableSubmit}
                      textAlign="right"
                      style={{ backgroundColor: "#0B7C80", cursor: "pointer" }}
                    >
                      Submit
                    </BtnArtSubmit>
                  )}
                </div>
              </FieldSection>
              <FieldSection>
                
                <div style={{ marginTop: "60px", marginBottom: "5px" }}>Choose main color.</div>
                
                <StyledSelect 
                    name="mainColor" 
                    value={mainColor} 
                    displayEmpty
                    disableUnderline={true}
                    onChange={this.handleMainColorChange}>
                        <MenuItem value="">None</MenuItem>
                          {colorsList.map((color) => (
                            <MenuItem key={color.id} value={color.id}>
                                {color.color_name}
                            </MenuItem>
                          ))}
                </StyledSelect>
                 
                <div style={{ marginTop: "30px", marginBottom: "5px" }}>Choose additional colors.</div>
                
                <StyledSelect 
                    name="additionalColors" 
                    multiple
                    value={additionalColors}
                    disableUnderline={true}
                    displayEmpty
                    renderValue={(selected) => {
                        if (selected.length === 0) {
                            return "None";
                        }
                        
                        return selected.length +" Selected ";
                    }}
                    onChange={this.handleAdditionalColorChange} >
                        <MenuItem disabled value="">None</MenuItem>
                        {additionalColorsList.map((color) => (
                            <MenuItem key={color.id} value={color.id}>
                                <StyledMultiSelectCheckbox 
                                    icon={<CircleUnchecked />} 
                                    checkedIcon={<CircleCheckedFilled />} 
                                    checked={additionalColors.indexOf(color.id) > -1} />
                                {color.color_name}
                            </MenuItem>
                        ))}
                </StyledSelect>
                        
                <p style={{ color: "#AAA", fontSize: "14px" }}>Note: Colors may be changed or adjusted at our discretion.</p>
                
              </FieldSection>
            </SubmitCard>
          </FormArtistSubmit>
        </TabArea>
      </SubmissionContainer>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  artistProfile: selectArtistProfile,
  submissionErrorMsg: selectSubmissionsErrorAlert,
  submissionSuccessMsg: selectSubmissionsSuccessAlert,
});

const mapDispatchToProps = (dispatch) => ({
  submissionCreateStart: (formData) =>
    dispatch(submissionCreateStart({ formData })),
  submissionErrorAlertClear: () => dispatch(submissionErrorAlertClear()),
  submissionSuccessAlertClear: () => dispatch(submissionSuccessAlertClear()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistSubmitArt);
